import { useMutation, useQuery, useQueryClient } from "react-query";
import { ProductOrder } from "../../components/common/OrderItem/OrderItem.types";
import { fetchFactory, RequestMethods } from "../../redux/actions/fetchFactory";

export const useProductOrders = (): {
  loading: boolean;
  orders?: ProductOrder[];
  errors?: any;
  onUpdateOrder: (order: ProductOrder) => void;
} => {
  const queryClient = useQueryClient();

  const { data: orders, isLoading, isError, isLoadingError } = useQuery<
    ProductOrder[]
  >(
    `orders`,
    async () =>
      fetchFactory({
        endpoint: "/api/product-orders/shop",
        method: RequestMethods.GET,
      }),
    // eslint-disable-next-line function-paren-newline
  );

  const updateProductOrder = useMutation(
    "orders",
    async (productOrder: ProductOrder) =>
      fetchFactory({
        endpoint: `/api/product-orders/shop/update/${productOrder.id}`,
        method: RequestMethods.PUT,
        errorMessage: "Failed to update product order",
        successMessage: "Updated product order successfully",
        body: {
          deliveryDetails: productOrder.deliveryDetails,
          orderStatus: productOrder.orderStatus,
        },
      }),
    {
      onSuccess: (response) => {
        queryClient.setQueryData("orders", response);
      },
    },
  );

  const onUpdateOrder = (productOrder: ProductOrder) => {
    updateProductOrder.mutate(productOrder);
  };

  return {
    orders,
    loading: isLoading,
    errors: isError || isLoadingError,
    onUpdateOrder,
  };
};
