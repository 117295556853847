import React from "react";
import { ZLoader } from "../loading/ZLoader";
import "./ScreenContainer.styles.css";
import { ScreenContainerProps } from "./ScreenContainer.types";

export const ScreenContainer: React.FC<ScreenContainerProps> = ({
  children,
  loading,
  title,
  footer,
}) => (
  <div className={`screen-container-outer-container ${!!footer && 'minmax-h-min'}`}>
    <div className="screen-container-inner-container">
      {title && (
        <>
          <h1 className="font-bold text-3xl text-gray-700">{title}</h1>
          <div className="w-full border-b h-2" />
        </>
      )}
      {loading ? (
        <div className="w-full flex justify-center p-8 col-span-full">
          <ZLoader />
        </div>
      ) : (
        children
      )}
    </div>
    {footer && !loading && footer}
  </div>
);
