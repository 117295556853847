import React, { useState } from "react";
import { City } from "../../admin/cities/Cities.types";
import { DeliveredModal } from "./DeliveredModal/DeliveredModal.component";
import { OrderStatus, ProductOrder } from "./OrderItem.types";
import { ShippingDetailsModal } from "./ShippingDetailsModal/ShippingDetailsModal.component";

export const OrderItem = ({
  item,
  onClick: onUpdateOrder,
}: {
  item: ProductOrder;
  onClick: (order: ProductOrder) => void;
}) => {
  const [shippingModalOpen, setShippingModalOpen] = useState(false);
  const [deliveredModalOpen, setDeliveredModalOpen] = useState(false);

  const toggleShippingModal = () => {
    setShippingModalOpen((prevState) => !prevState);
  };

  const toggleDeliveredModal = () => {
    setDeliveredModalOpen((prevState) => !prevState);
  };

  const onClick = () => {
    if (item.orderStatus === OrderStatus.CREATED) {
      toggleShippingModal();
    } else if (item.orderStatus === OrderStatus.SHIPPED) {
      toggleDeliveredModal();
    }
  };

  const selectedProduct = item?.productGroup?.products?.find(
    (p) => p._id === item.product,
    // eslint-disable-next-line function-paren-newline
  );

  return (
    <>
      <div className="w-full rounded-md p-4 bg-white text-sm" onClick={onClick}>
        <div className="pb-2 text-gray-700 font-bold flex flex-row justify-between">
          <div className="flex flex-row">
            {item?.productGroup?.name}
            <div className="pl-2 text-green-500">{`x${item?.quantity}`}</div>
          </div>
          <div className="text-zimx-indigo-dark">
            {`$${selectedProduct?.price}`}
          </div>
        </div>
        <div className="text-gray-500 font-bold flex flex-row justify-between">
          <div>{(item?.deliveryAddress?.city as City).name}</div>
          <div className="text-gray-indigo-dark">
            {Intl.DateTimeFormat("en-GB").format(new Date(item?.createdAt!))}
          </div>
        </div>
        {item.orderStatus === OrderStatus.SHIPPED && (
          <div className="text-gray-500 font-bold flex flex-row justify-between">
            <div>{item.deliveryDetails?.deliveryCompany}</div>
            <div className="text-gray-indigo-dark">
              {item.deliveryDetails?.phoneNumber}
            </div>
          </div>
        )}
      </div>
      <ShippingDetailsModal
        onToggle={toggleShippingModal}
        isOpen={shippingModalOpen}
        productOrder={item}
        onUpdateOrder={onUpdateOrder}
      />
      <DeliveredModal
        onToggle={toggleDeliveredModal}
        isOpen={deliveredModalOpen}
        order={item}
        onUpdateOrder={onUpdateOrder}
      />
    </>
  );
};
