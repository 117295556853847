import {
  IShop,
  Product,
  ProductGroup,
} from "../../../redux/reducers/shop.reducer";
import { IUser } from "../../../redux/reducers/user.reducer";
import { Address } from "../../../screens/Address";

export enum OrderStatus {
  CREATED = "CREATED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  SEEN = "SEEN",
  SHIPPED = "SHIPPED",
  RETURNED = "RETURNED",
  DELIVERED = "DELIVERED",
}

export interface DeliveryDetails {
  phoneNumber: string;
  deliveryCompany: string;
  trackingNumber: string;
}

export interface ProductOrder {
  product: Partial<Product>;

  productGroup: Partial<ProductGroup>;

  deliveryAddress: Partial<Address>;

  quantity: number;

  orderStatus: OrderStatus;

  trackingNumber: string;

  shop: IShop;

  paymentRef: string;

  total: number;

  user: IUser;

  cartId: string;

  createdAt: string;

  deliveryDetails?: DeliveryDetails;

  id: string;
}
